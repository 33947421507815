import { styled } from 'stitches.config';
import { imageFiles } from 'utils';

export const Footer = () => {
  return (
    <Grid>
      <Item>
        <LogoLeft src={imageFiles.logo} alt="gsk" />
        <Company>
          PM-DE-MPL-BTHC-230010 <br /> Standkonzept NoHuman Stand EGPA <br /> ©
          2023 GlaxoSmithKline GmbH & Co. KG
        </Company>
      </Item>
      <Item>
        <Text>
          Die Inhalte richten sich an medizinische Fachkreise in Deutschland
        </Text>
      </Item>
      <Item flexEnd>
        <LogoRight src={imageFiles.logo2} alt="gsk" />
      </Item>
    </Grid>
  );
};

const Grid = styled('div', {
  position: 'absolute',
  bottom: 0,
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  backgroundColor: 'color-mix(in srgb, $primary, transparent 40%)',
});

const Item = styled('div', {
  display: 'flex',
  alignItems: 'center',
  variants: {
    flexEnd: {
      true: {
        justifyContent: 'flex-end',
      },
    },
  },
});

const LogoLeft = styled('img', {
  height: 62,
});

const LogoRight = styled('img', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: 75,
});

const Company = styled('div', {
  marginLeft: 5,
  fontSize: 12,
  color: '$textLighter',
});

const Text = styled('div', {
  fontSize: 15,
  color: '$textLighter',
  letterSpacing: '-0.51px',
  fontWeight: 500,
  textAlign: 'center',
});
