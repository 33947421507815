import { styled } from 'stitches.config';
import dotLottieJson from '../../assets/btn_dot_lottie.json';
import Lottie from 'react-lottie-player';
import { Dot } from './dots';
import { useEffect, useRef } from 'react';
import { imageFiles } from 'utils';

type Props = {
  dot: Dot;
  isActive: boolean;
  onTap: (id: number) => void;
};
export const DotButton = ({
  dot: { id, left, top },
  isActive,
  onTap,
}: Props) => {
  const onClick = () => onTap(id);
  const ref = useRef<(HTMLDivElement & { play: () => {} }) | null>(null);

  useEffect(() => {
    setTimeout(() => {
      ref?.current?.play();
    }, 500 + id * 250);
  }, [id]);

  return (
    <Button style={{ left, top }} onClick={onClick}>
      <Lottie
        ref={ref}
        animationData={dotLottieJson}
        loop
        play={false}
        speed={1}
        style={{ width: '100%', height: '100%', opacity: isActive ? 0.5 : 1 }}
      />
      <Active src={imageFiles.dotActive} isActive={isActive} alt="active dot" />
    </Button>
  );
};

const Button = styled('button', {
  position: 'absolute',
  width: 70,
  height: 70,
  background: 'none',
  border: 'none',
  cursor: 'pointer',
});

const Active = styled('img', {
  position: 'absolute',
  left: -38,
  top: -38,
  width: 146,
  height: 146,
  opacity: 0,
  transition: 'opacity 0.35s ease-in-out',
  variants: {
    isActive: {
      true: {
        opacity: 1,
      },
    },
  },
});
