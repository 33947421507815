import { ReactNode } from 'react';
import { styled } from 'stitches.config';

type Props = {
  error: boolean;
  children: ReactNode;
};

export const ErrorMessage = ({ error = false, children }: Props) => (
  <Message error={error}>{children}</Message>
);

const Message = styled('div', {
  paddingLeft: 45,
  marginTop: 10,
  fontSize: 18,
  color: '$error',
  visibility: 'hidden',
  variants: {
    error: {
      true: {
        visibility: 'visible',
      },
    },
  },
});
