import { styled } from 'stitches.config';
import { Dot } from './dots';
import { imageFiles } from 'utils';

type Props = {
  dot?: Dot;
};
export const Tooltip = ({ dot }: Props) => {
  if (!dot) return null;

  const { left, top, headline, description } = dot;

  return (
    <Content style={{ left: left + 70, top: top + 4 }}>
      <Triangle src={imageFiles.redTriangle} alt="" />
      <Box>
        <Headline>{headline}</Headline>
        <Body>{description}</Body>
      </Box>
    </Content>
  );
};

const Content = styled('div', {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '$light',
  padding: '19px 23px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.04)',
  width: 364,
  maxWidth: 364,
  borderRadius: 4,
});

const Triangle = styled('img', {
  width: 20,
  height: 23,
  marginTop: 4,
});

const Box = styled('div', {
  marginLeft: 10,
});

const Headline = styled('p', {
  color: '$red',
  fontWeight: 500,
  fontSize: 31,
  lineHeight: 1,
});

const Body = styled('p', {
  fontSize: 15,
  lineHeight: 1.2,
  marginTop: 3,
});
