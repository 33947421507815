import { StartPage, ExportPage } from './components';
import { globalCss } from './stitches.config';
import { Routes, Route } from 'react-router-dom';

function App() {
  globalStyles();

  return (
    <Routes>
      <Route path="/" element={<StartPage />} />
      <Route path="/export" element={<ExportPage />} />
    </Routes>
  );
}

export default App;

const globalStyles = globalCss({});
