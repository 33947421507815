import * as Progress from '@radix-ui/react-progress';
import { styled } from '@stitches/react';

type Props = {
  progress: number;
};

export const ClosingProgress = ({ progress }: Props) => {
  return (
    <ProgressBar className="ProgressRoot" value={progress}>
      <ProgressIndicator
        className="ProgressIndicator"
        style={{ transform: `translateX(${progress}%)` }}
      />
    </ProgressBar>
  );
};

const ProgressBar = styled(Progress.Root, {
  width: '100%',
  height: 2,
  backgroundColor: '$darkGreen',
  borderRadius: 6,
  position: 'relative',
  overflow: 'hidden',
  transform: 'translateZ(0)',
});

const ProgressIndicator = styled(Progress.Indicator, {
  backgroundColor: 'rgb(215, 215, 215)',
  width: '100%',
  height: '100%',
});
