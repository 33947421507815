import { CSS, styled } from '@stitches/react';
import { forwardRef, InputHTMLAttributes } from 'react';
import { ErrorMessage } from '..';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean;
  errorMessage?: string;
  unit?: string;
  value?: string;
  css?: CSS;
};

export const CustomInput = forwardRef<HTMLInputElement, Props>(
  (
    { error = false, unit, value, errorMessage, ...props }: Props,
    forwardedRef
  ) => {
    return (
      <Field>
        <Input
          maxLength={80}
          ref={forwardedRef}
          error={error}
          value={value}
          autoComplete="off"
          {...props}
        />
        <ErrorMessage error={error}>{errorMessage}</ErrorMessage>
      </Field>
    );
  }
);

CustomInput.displayName = 'CustomInput';

const Field = styled('div', {
  flex: 1,
});

const Input = styled('input', {
  height: 56,
  width: '100%',
  border: '1px solid $border',
  borderRadius: 30,
  padding: '0 45px',
  fontSize: 25,
  '&:placeholder': {
    color: '$placeholder',
  },
  variants: {
    error: {
      true: {
        borderColor: '$error',
      },
    },
  },
});
