import { ComponentProps, forwardRef } from 'react';
import * as Select from '@radix-ui/react-select';
import { styled } from 'stitches.config';

type Option = {
  value: string;
  label: string;
};

type Props = ComponentProps<typeof Select.Root> & {
  options: Option[];
  placeholder?: string;
  error?: boolean;
};

export const CustomSelect = ({
  options,
  value,
  placeholder,
  error = false,
  ...props
}: Props) => {
  const selectedOption = options.find((v) => v.value === value);
  return (
    <Select.Root value={selectedOption?.value ?? undefined} {...props}>
      <SelectTrigger isPlaceholder={!selectedOption} error={error}>
        <Select.Value placeholder={placeholder} />
      </SelectTrigger>
      <Select.Portal>
        <SelectContent position="popper">
          <SelectViewport>
            <Select.Group>
              {options.map((option) => {
                const isSelected = value === option.value;
                return (
                  <SelectItem
                    isSelected={isSelected}
                    key={option.label}
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                );
              })}
            </Select.Group>
          </SelectViewport>
        </SelectContent>
      </Select.Portal>
    </Select.Root>
  );
};

const SelectTrigger = styled(Select.SelectTrigger, {
  all: 'unset',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  paddingLeft: 45,
  cursor: 'pointer',
  height: 56,
  borderRadius: 30,
  border: '1px solid $border',
  fontSize: 25,
  variants: {
    isPlaceholder: {
      true: {
        '& > span': {
          color: '$placeholder',
        },
      },
    },
    error: {
      true: {
        border: '1px solid $error',
      },
    },
  },
});

const SelectContent = styled(Select.Content, {
  marginTop: 10,
  width: 520,
  overflow: 'hidden',
  border: '1px solid $border',
  backgroundColor: '#FAF9F7',
  borderRadius: 30,
});

const SelectViewport = styled(Select.Viewport, {});

const StyledItem = styled(Select.Item, {
  all: 'unset',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: 15,
  position: 'relative',
  userSelect: 'none',
  cursor: 'pointer',
  fontSize: 25,
  '&:hover': {
    opacity: 0.7,
  },
  '&[data-disabled]': {
    pointerEvents: 'none',
  },
  variants: {
    isSelected: {
      true: {
        fontWeight: 600,
        backgroundColor: '$darkGreen',
        color: '#fff',
      },
    },
  },
});

const SelectItem = forwardRef(
  ({ children, isSelected, selectedItemIcon, ...props }: any, forwardedRef) => {
    return (
      <StyledItem isSelected={isSelected} {...props} ref={forwardedRef}>
        <SelectItemWrap style={{ width: '100%' }}>
          <Select.ItemText>{children}</Select.ItemText>
        </SelectItemWrap>
      </StyledItem>
    );
  }
);

SelectItem.displayName = 'SelectItem';

const SelectItemWrap = styled('div', {
  padding: '0 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
