import { styled } from 'stitches.config';
import { Controller, Control } from 'react-hook-form';
import { salutationOptions } from '..';
import { DataSchemaType, useDataStoreValues } from 'store';

type Props = {
  control: Control<DataSchemaType>;
};

export const Step5 = ({ control }: Props) => {
  const { formData } = useDataStoreValues();
  const {
    email,
    salutation,
    title,
    firstName,
    lastName,
    specialization,
    institution,
    street,
    house_number,
    zip_code,
    city,
  } = formData;
  const getSalutaion = (): string | undefined =>
    salutation !== salutationOptions[salutationOptions.length - 1].value
      ? salutation
      : undefined;

  return (
    <Content>
      <Message>
        Bitte überprüfen Sie Ihre Angaben und bestätigen Sie die Verarbeitung
        Ihrer Daten
      </Message>
      <PersonalData>
        {getSalutaion()} {title} {firstName} {lastName} <br />
        {email} <br />
        {specialization} <br />
        {institution} <br />
        {street} {house_number} <br />
        {zip_code} {city}
      </PersonalData>
      <Label>
        <Controller
          control={control}
          name="isConsentChecked"
          defaultValue={true}
          render={({ field: { onChange, value, ref, ...props } }) => (
            <Checkbox
              ref={ref}
              type="checkbox"
              onChange={onChange}
              {...props}
            />
          )}
        />
        <Text>
          Ja, ich bin damit einverstanden, dass GlaxoSmithKline GmbH & Co. KG
          (GSK) diese Angaben erhält und stimme den Nutzungsbedingungen
          (https://terms.gsk.com/de-de/pharmaceuticals/default/) und
          Datenschutzbestimmungen
          (https://privacy.gsk.com/de-de/pharmaceuticals/default/) von GSK zu.
          GSK kann meine Daten außerdem nutzen, um mich über Produktneuheiten
          und Services personalisiert zu informieren. Um das Informationsangebot
          persönlich attraktiver zu gestalten, kann GSK meine Interaktionen mit
          GSK speichern und zusammenführen. Dies kann ich jederzeit mittels
          E-Mail an digitale.services@gsk.com widerrufen.
        </Text>
      </Label>
    </Content>
  );
};

const Content = styled('div', {
  marginTop: 50,
});

const Message = styled('div', {
  marginBottom: 35,
  textAlign: 'center',
  fontSize: 25,
  color: '$placeholder',
  variants: {
    error: {
      true: {
        color: '$error',
        fontWeight: 600,
      },
    },
  },
});

const PersonalData = styled('div', {
  margin: '30px 0 45px',
  textAlign: 'center',
  fontSize: 25,
  fontWeight: 600,
  color: '$border',
  lineHeight: '40px',
  whiteSpace: 'nowrap',
});

const Label = styled('label', {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
});

const Checkbox = styled('input', {
  margin: '5px 30px 0 0',
  transform: 'scale(2)',
});

const Text = styled('div', {
  fontSize: 17,
  lineHeight: '21px',
  letterSpacing: '-0.56px',
  color: '$darkGreen',
  maxWidth: 840,
});
