import { createStitches } from '@stitches/react';

export const {
  styled,
  keyframes,
  css,
  getCssText,
  globalCss,
  theme,
  createTheme,
} = createStitches({
  theme: {
    colors: {
      light: '#fff',
      lightMedium: '#fafaf8',

      textLighter: '#fafaf8',
      textLight: '#F0F0F0',
      textMedium: '#979797',
      textDark: '#1F1F1F',

      primary: '#630716',

      red: '#D71A26',
      redDark: '#630716',

      darkGreen: '#233636',

      border: '#353839',
      placeholder: '#46494B',
      error: '#D81A26',
    },
    space: {},
    fontSizes: {},
    fontWeights: {},
    lineHeights: {},
    letterSpacings: {},
    borderWidths: {},
    borderStyles: {},
    radii: {},
    shadows: {},
    zIndices: {},
    transitions: {},
  },
});
