import { styled } from 'stitches.config';
import { imageFiles } from 'utils';
import { DotButton } from './DotButton';
import { Tooltip } from './Tooltip';
import { useEffect, useState } from 'react';
import { Dot, dots } from './dots';

export const Person = () => {
  const [activeDotId, setActiveDotId] = useState<number | undefined>(3);
  const [activeDot, setActiveDot] = useState<Dot | undefined>(undefined);
  const onDotTap = (dotId: number) => {
    if (dotId === activeDotId) {
      // setActiveDotId(undefined);
    } else {
      setActiveDotId(dotId);
    }
  };
  useEffect(() => {
    const nextActiveDot = dots.find(({ id }) => id === activeDotId);
    setActiveDot(nextActiveDot);
  }, [activeDotId, activeDot]);
  return (
    <Content>
      {dots.map((dot) => (
        <DotButton
          key={dot.id}
          dot={dot}
          isActive={activeDotId === dot.id}
          onTap={onDotTap}
        />
      ))}
      {activeDotId && <Tooltip dot={activeDot} />}
      <Schema src={imageFiles.schemaPerson} alt="person" />
    </Content>
  );
};
const Content = styled('div', {
  position: 'relative',
  width: 400,
  height: 960,
});

const Schema = styled('img', {
  width: 400,
  height: 1311.06,
  zIndex: -1,
});
