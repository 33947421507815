import { styled } from 'stitches.config';
import { useDataStore } from 'store';

export const Stepper = () => {
  const { step } = useDataStore();
  return (
    <TopMid>
      <TopMidText>
        {(() => {
          switch (step) {
            case 1:
              return 'E-Mail-Adresse';
            case 2:
              return 'Themen zu EGPA';
            case 3:
              return 'Personenangaben';
            case 4:
              return 'Institution / Praxis';
            case 5:
              return 'Prüfen…';
            default:
              return null;
          }
        })()}
      </TopMidText>
      <Slider>
        <SliderCircle isActive={step >= 1} />
        <SliderLine />
        <SliderCircle isActive={step >= 2} />
        <SliderLine />
        <SliderCircle isActive={step >= 3} />
        <SliderLine />
        <SliderCircle isActive={step >= 4} />
        <SliderLine />
        <SliderCircle isActive={step >= 5} />
      </Slider>
    </TopMid>
  );
};

const TopMid = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const TopMidText = styled('div', {
  color: '$border',
  fontSize: 25,
  letterSpacing: -1,
  textAlign: 'center',
});

const Slider = styled('div', {
  marginTop: 15,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const SliderCircle = styled('div', {
  height: 15,
  width: 15,
  borderRadius: 50,
  border: '1px solid $border',
  variants: {
    isActive: {
      true: {
        background: '$border',
      },
    },
  },
});

const SliderLine = styled('div', {
  width: 29,
  height: 1,
  background: '$border',
});
