import { styled } from 'stitches.config';
import { DialogWizard, Footer, MainContent } from '../components';

export const StartPage = () => {
  return (
    <Container>
      <MainContent />
      <DialogWizard />
      <Footer />
    </Container>
  );
};

const Container = styled('div', {
  position: 'relative',
  width: '100vw',
  height: '100vh',
  backgroundColor: '$lightMedium',
});
