import { styled } from 'stitches.config';
import {
  Controller,
  Control,
  FieldErrors,
  UseFormSetFocus,
} from 'react-hook-form';
import { CustomInput } from '..';
import { DataSchemaType } from 'store';
import { KeyboardEvent } from 'react';

type Props = {
  errors: FieldErrors<DataSchemaType>;
  control: Control<DataSchemaType>;
  setFocus: UseFormSetFocus<DataSchemaType>;
};

const inputNames = [
  'institution',
  'street',
  'house_number',
  'zip_code',
  'city',
] as const;

type InputNamesType = (typeof inputNames)[number];

export const Step4 = ({ errors, control, setFocus }: Props) => {
  // switch to next input when click "Enter"
  const handleOnKeyDown = (
    event: KeyboardEvent<HTMLInputElement>,
    name: InputNamesType
  ) => {
    if (event.key !== 'Enter') return;
    setFocus(inputNames[inputNames.indexOf(name) + 1]);
  };

  const hasError = Object.keys(errors).length > 0;

  return (
    <Content>
      <Message error={hasError}>
        {hasError
          ? 'Bitte füllen Sie alle Felder korrekt aus'
          : 'Bitte machen Sie Angaben zur Ihrer Institution / Praxis'}
      </Message>
      <Row>
        <Controller
          control={control}
          name="institution"
          defaultValue=""
          render={({ field: { onChange, value, ref, name } }) => (
            <CustomInput
              onKeyDown={(e) => handleOnKeyDown(e, name)}
              ref={ref}
              error={!!errors?.institution}
              type="text"
              value={value}
              onChange={onChange}
              placeholder="Name der Institution / Praxis*"
              errorMessage="Bitte geben Sie Ihre Institution / Praxis an"
            />
          )}
        />
      </Row>
      <Row>
        <Controller
          control={control}
          name="street"
          defaultValue=""
          render={({ field: { onChange, value, ref, name } }) => (
            <CustomInput
              onKeyDown={(e) => handleOnKeyDown(e, name)}
              ref={ref}
              error={!!errors?.street}
              type="text"
              value={value}
              onChange={onChange}
              placeholder="Straße*"
              errorMessage="Bitte geben Sie Ihre Straße an"
            />
          )}
        />
        <Controller
          control={control}
          name="house_number"
          defaultValue=""
          render={({ field: { onChange, value, ref, name } }) => (
            <CustomInput
              onKeyDown={(e) => handleOnKeyDown(e, name)}
              ref={ref}
              error={!!errors?.house_number}
              type="text"
              value={value}
              onChange={onChange}
              placeholder="Hausnummer*"
              errorMessage="Bitte geben Sie Ihre Hausnummer an"
            />
          )}
        />
        <Controller
          control={control}
          name="zip_code"
          defaultValue=""
          render={({ field: { onChange, value, ref, name } }) => (
            <CustomInput
              min={0}
              max={999999}
              onKeyDown={(e) => handleOnKeyDown(e, name)}
              ref={ref}
              error={!!errors?.zip_code}
              type="number"
              value={value}
              onChange={onChange}
              placeholder="Postleitzahl*"
              errorMessage="Bitte geben Sie Ihre Postleitzahl an"
            />
          )}
        />
        <Controller
          control={control}
          name="city"
          defaultValue=""
          render={({ field: { onChange, value, ref } }) => (
            <CustomInput
              ref={ref}
              error={!!errors?.city}
              type="text"
              value={value}
              onChange={onChange}
              placeholder="Ort*"
              errorMessage="Bitte geben Sie Ihren Ort an"
            />
          )}
        />
      </Row>
    </Content>
  );
};

const Content = styled('div', {
  marginTop: 65,
});

const Message = styled('div', {
  marginBottom: 45,
  textAlign: 'center',
  fontSize: 25,
  color: '$placeholder',
  variants: {
    error: {
      true: {
        color: '$error',
        fontWeight: 600,
      },
    },
  },
});

const Row = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  gap: 30,
  marginBottom: 30,
});
