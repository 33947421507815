import { styled } from 'stitches.config';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Stepper } from './Stepper';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import { Step6 } from './Step6';
import { imageFiles } from 'utils';
import {
  DataSchema,
  DataSchemaType,
  dataStoreDefaults,
  useDataStore,
  useDataStoreValues,
} from 'store';
import { db } from 'db';
import { v4 as uuidv4 } from 'uuid';
import {
  emailToOpenExportView,
  wizardStepSuccessTimerInSec,
  wizardStepTimerInSec,
} from '../../constants';
import { z } from 'zod';
import { ClosingProgress } from '../generic/ClosingProgress';

export const UserForm = () => {
  const { formData, step } = useDataStoreValues();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    reset,
    setFocus,
    setValue,
  } = useForm<DataSchemaType>({
    defaultValues: formData,
    resolver: zodResolver(DataSchema),
  });
  const emailSchema = z.string().email();
  const [counter, setCounter] = useState(wizardStepTimerInSec);

  const closeModal = useCallback(() => {
    useDataStore.setState(dataStoreDefaults);
    reset();
  }, [reset]);

  useEffect(() => {
    if (counter === 0) {
      closeModal();
    }
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer as NodeJS.Timer);
    };
  }, [counter, closeModal]);

  const refreshCounter = (step: number) => {
    if (step === 6) {
      setCounter(wizardStepSuccessTimerInSec);
    } else {
      setCounter(wizardStepTimerInSec);
    }
  };

  const unsetCounter = () => {
    setCounter(0);
  };

  const prevStep = () =>
    useDataStore.setState((prev) => {
      const step = --prev.step;
      refreshCounter(step);
      return { ...prev, step };
    });
  const nextStep = () =>
    useDataStore.setState((prev) => {
      const step = ++prev.step;
      refreshCounter(step);
      return { ...prev, step };
    });

  // Save data to the indexedDB
  const saveData = async (data: DataSchemaType) => {
    try {
      await db.users.add({ id: uuidv4(), date: new Date(), ...data });
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = (data: DataSchemaType, event: any) => {
    const {
      interest,
      interestOther,
      salutation,
      title,
      email,
      firstName,
      lastName,
      specialization,
      institution,
      street,
      house_number,
      zip_code,
      city,
    } = data;
    // Email
    if (step === 1) {
      try {
        // we need to check if a user types an email, we can't use the "email()" feature
        // from zod in the store because it invokes "email error" on the first step in the form
        emailSchema.parse(email);
      } catch (error) {
        setError('email', {});
        return;
      }

      if (email === emailToOpenExportView) window.location.replace('/export');

      if (email) {
        nextStep();
      }
    }

    // Interest
    if (step === 2) {
      if (!interest?.length) setError('interest', {});
      if (!!interest?.length) {
        if (interest?.includes('Sonstiges') && !interestOther?.length) {
          setError('interestOther', {});
        } else {
          nextStep();
        }
      }
    }

    // Personal info
    if (step === 3) {
      if (!salutation) setError('salutation', {});
      if (!title) setError('title', {});
      if (!lastName) setError('lastName', {});
      if (!firstName) setError('firstName', {});
      if (!specialization) setError('specialization', {});

      if (salutation && title && firstName && lastName && specialization) {
        nextStep();
      }
    }

    // Institution
    if (step === 4) {
      if (!institution) setError('institution', {});
      if (!street) setError('street', {});
      if (!house_number) setError('house_number', {});
      if (!zip_code) setError('zip_code', {});
      if (!city) setError('city', {});

      if (institution && street && house_number && zip_code && city) {
        nextStep();
      }
    }

    // Save data to the indexedDB
    if (step === 5) {
      saveData(data);
      nextStep();
    }
  };

  useEffect(() => {
    const subscription = watch((formData) => {
      useDataStore.setState((state) => ({ ...state, formData }));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      {step < 6 && (
        <TopBar>
          {step === 1 ? (
            <TopBarLeft onClick={closeModal}>
              <CloseIcon src={imageFiles.closeIcon} alt="close" />
              <ProgressWrap>
                schließen
                <ClosingProgress
                  progress={(counter / wizardStepTimerInSec) * 100}
                />
              </ProgressWrap>
            </TopBarLeft>
          ) : (
            <TopBarLeft onClick={prevStep}>
              <BackIcon src={imageFiles.backIcon} alt="close" />
              <ProgressWrap>
                zurück
                <ClosingProgress
                  progress={(counter / wizardStepTimerInSec) * 100}
                />
              </ProgressWrap>
            </TopBarLeft>
          )}
          <Stepper />
          <ContinueBtn onClick={handleSubmit(onSubmit)}>
            {step === 5 ? 'Absenden' : 'Weiter'}
            <ContinueIcon src={imageFiles.continueIcon} alt="close" />
          </ContinueBtn>
        </TopBar>
      )}
      {(() => {
        switch (step) {
          case 1:
            return <Step1 errors={errors} control={control} />;
          case 2:
            return (
              <Step2
                errors={errors}
                control={control}
                watch={watch}
                setValue={setValue}
              />
            );
          case 3:
            return (
              <Step3 setFocus={setFocus} errors={errors} control={control} />
            );
          case 4:
            return (
              <Step4 setFocus={setFocus} errors={errors} control={control} />
            );
          case 5:
            return <Step5 control={control} />;
          case 6:
            return <Step6 counter={counter} onClose={unsetCounter} />;
          default:
            return null;
        }
      })()}
    </>
  );
};

const TopBar = styled('div', {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
});

const TopBarLeft = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontSize: 17,
  fontWeight: 500,
  cursor: 'pointer',
});

const CloseIcon = styled('img', {
  width: 20,
  height: 20,
  paddingTop: 2,
  marginRight: 10,
});

const ContinueBtn = styled('button', {
  marginLeft: 'auto',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  fontSize: 30,
  fontWeight: 500,
  height: 77,
  backgroundColor: '#4D0B14',
  color: '#FAF9F7',
  borderRadius: '50px',
  textAlign: 'center',
  paddingRight: 25,
  paddingLeft: 45,
  cursor: 'pointer',
  border: 'none',
});

const ProgressWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const BackIcon = styled('img', {
  height: 40,
  marginRight: 10,
  paddingTop: 3,
});

const ContinueIcon = styled('img', {
  height: 50,
  paddingTop: 2,
  marginLeft: 20,
});
