import { styled } from 'stitches.config';
import {
  Controller,
  Control,
  FieldErrors,
  UseFormSetFocus,
} from 'react-hook-form';
import { CustomInput, CustomSelect, ErrorMessage } from '..';
import { DataSchemaType } from 'store';
import { KeyboardEvent } from 'react';

type Props = {
  errors: FieldErrors<DataSchemaType>;
  control: Control<DataSchemaType>;
  setFocus: UseFormSetFocus<DataSchemaType>;
};

export const salutationOptions = [
  { value: 'Herr', label: 'Herr' },
  { value: 'Frau', label: 'Frau' },
  { value: 'Keine Angabe', label: 'Keine Angabe' },
];

const inputNames = [
  'salutation',
  'title',
  'specialization',
  'firstName',
  'lastName',
] as const;

type InputNamesType = (typeof inputNames)[number];

export const Step3 = ({ errors, control, setFocus }: Props) => {
  // switch to next input when click "Enter"
  const handleOnKeyDown = (
    event: KeyboardEvent<HTMLInputElement>,
    name: InputNamesType
  ) => {
    if (event.key !== 'Enter') return;
    setFocus(inputNames[inputNames.indexOf(name) + 1]);
  };

  const hasError = Object.keys(errors).length > 0;

  return (
    <Content>
      <Message error={hasError}>
        {hasError
          ? 'Bitte füllen Sie alle Felder korrekt aus'
          : 'Bitte machen Sie Angaben zur Ihrer Person'}
      </Message>
      <Row>
        <Col>
          <Row>
            <Wrap>
              <Controller
                control={control}
                name="salutation"
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <CustomSelect
                    onValueChange={onChange}
                    value={value}
                    options={salutationOptions}
                    placeholder="Anrede*"
                  />
                )}
              />
              <ErrorMessage error={!!errors.salutation}>
                Bitte geben Sie Ihre Anrede an
              </ErrorMessage>
            </Wrap>

            <Controller
              control={control}
              name="title"
              defaultValue=""
              render={({ field: { onChange, value, ref, name } }) => (
                <CustomInput
                  autoFocus
                  onKeyDown={(e) => handleOnKeyDown(e, name)}
                  ref={ref}
                  error={!!errors?.title}
                  type="text"
                  value={value}
                  onChange={onChange}
                  placeholder="Titel*"
                  errorMessage="Bitte geben Sie Ihren Titel an"
                />
              )}
            />
          </Row>
          <Controller
            control={control}
            name="firstName"
            defaultValue=""
            render={({ field: { onChange, value, ref, name } }) => (
              <CustomInput
                onKeyDown={(e) => handleOnKeyDown(e, name)}
                ref={ref}
                error={!!errors?.firstName}
                type="text"
                value={value}
                onChange={onChange}
                placeholder="Vorname*"
                errorMessage="Bitte geben Sie Ihren Vornamen an"
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            control={control}
            name="specialization"
            defaultValue=""
            render={({ field: { onChange, value, ref } }) => (
              <CustomInput
                ref={ref}
                error={!!errors?.specialization}
                type="text"
                value={value}
                onChange={onChange}
                placeholder="Fachrichtung*"
                errorMessage="Bitte geben Sie Ihre Fachrichtung an"
              />
            )}
          />

          <Wrap style={{ marginTop: 10 }}>
            <Controller
              control={control}
              name="lastName"
              defaultValue=""
              render={({ field: { onChange, value, ref, name } }) => (
                <CustomInput
                  onKeyDown={(e) => handleOnKeyDown(e, name)}
                  ref={ref}
                  error={!!errors?.lastName}
                  type="text"
                  value={value}
                  onChange={onChange}
                  placeholder="Nachname*"
                  errorMessage="Bitte geben Sie Ihren Nachnamen an"
                />
              )}
            />
          </Wrap>
        </Col>
      </Row>
    </Content>
  );
};

const Content = styled('div', {
  marginTop: 65,
});

const Message = styled('div', {
  marginBottom: 45,
  textAlign: 'center',
  fontSize: 25,
  color: '$placeholder',
  variants: {
    error: {
      true: {
        color: '$error',
        fontWeight: 600,
      },
    },
  },
});

const Col = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 9,
});

const Row = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  gap: 30,
});

const Wrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});
