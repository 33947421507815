import { styled } from 'stitches.config';
import * as Dialog from '@radix-ui/react-dialog';
import { UserForm } from './UserForm';
import { useDataStore, useDataStoreValues } from 'store';
import ctaButtonLottie from '../../assets/btn_cta_lottie.json';
import Lottie from 'react-lottie-player';

export const DialogWizard = () => {
  const { isFormOpen, step } = useDataStoreValues();

  return (
    <Dialog.Root open={isFormOpen}>
      <Dialog.Trigger asChild>
        <Trigger
          onClick={() => useDataStore.setState(() => ({ isFormOpen: true }))}
        >
          <Lottie
            animationData={ctaButtonLottie}
            loop
            play
            speed={1}
            style={{ width: 380, height: 380 }}
          />
        </Trigger>
      </Dialog.Trigger>
      <Dialog.Portal>
        <DialogOverlay />
        <DialogContent isSuccess={step === 6}>
          <UserForm />
          {step === 1 && (
            <BottomContent>
              Die Angabe Ihrer persönlichen Daten ist freiwillig, Ihre Daten
              werden auf keinen Fall zur kommerziellen Nutzung an Dritte
              weitergegeben. <br /> Es ist allerdings möglich, dass wir für
              einzelne Informations- oder Werbemaßnahmen Ihre Daten an einen von
              uns beauftragten Dienstleister weitergeben, der Ihre Daten
              ausschließlich nach unseren Vorgaben für eine von uns veranlasste
              konkrete Maßnahme verwenden darf. <br /> Ihre Einwilligung können
              Sie jederzeit für die Zukunft widerrufen.
            </BottomContent>
          )}
          <ApprovalCode>
            PM-DE-MPL-BTHC-230010 <br /> Standkonzept NoHuman Stand EGPA
          </ApprovalCode>
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const DialogOverlay = styled(Dialog.Overlay, {
  background: 'rgba(0,0,0,0.5)',
  position: 'fixed',
  inset: 0,
});

const Trigger = styled('div', {
  position: 'absolute',
  bottom: 345,
  left: 80,
  cursor: 'pointer',
});

const DialogContent = styled(Dialog.Content, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '$light',
  inset: 0,
  margin: '35px auto 0',
  width: 'calc(100vw - 80px)',
  height: 'calc(100vh - 80px)',
  maxHeight: 860,
  position: 'fixed',
  padding: '35px 85px 45px',
  clipPath:
    'polygon(0 0,calc(100% - 80.00px) 0,100% 80.00px,100% 100%,40.00px 100%,0 calc(100% - 40.00px))',
  variants: {
    isSuccess: {
      true: {
        justifyContent: 'center',
      },
    },
  },
});

const BottomContent = styled('div', {
  textAlign: 'left',
  fontSize: 14,
  letterSpacing: '-0.48px',
  color: '$darkGreen',
  maxWidth: '96%',
  margin: '40px auto',
  lineHeight: '18px',
});

const ApprovalCode = styled('div', {
  textAlign: 'right',
  fontSize: 14,
  letterSpacing: '-0.48px',
  color: '$darkGreen',
  margin: 'auto 0 0',
  lineHeight: '18px',
});
