import { styled } from 'stitches.config';
import {
  Controller,
  Control,
  UseFormSetValue,
  UseFormWatch,
  FieldErrors,
} from 'react-hook-form';
import { CustomInput } from '..';
import { DataSchemaType } from 'store';

type Props = {
  errors: FieldErrors<DataSchemaType>;
  control: Control<DataSchemaType>;
  setValue: UseFormSetValue<DataSchemaType>;
  watch: UseFormWatch<DataSchemaType>;
};

export const Step2 = ({ errors, control, watch, setValue }: Props) => {
  const interestArray = watch('interest') ?? [];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (interestArray.includes(value)) {
      setValue('interest', [...interestArray.filter((v) => v !== value)]);
    } else {
      setValue('interest', [...interestArray, value]);
    }
  };

  const hasError = Object.keys(errors).length > 0;
  let message = hasError
    ? 'Bitte wählen Sie mindestens einen Bereich aus'
    : 'An welchen Themen zu EGPA haben Sie Interesse?';
  if (!!errors.interestOther)
    message = 'Bitte machen Sie eine Angabe für Sonstiges';

  return (
    <Box>
      <Message error={hasError}>{message}</Message>
      <Content>
        <Col>
          <Label>
            <Controller
              control={control}
              name="interest"
              render={({ field: { onChange, value, ref, ...props } }) => (
                <Checkbox
                  checked={interestArray.includes('Diagnostik')}
                  value={'Diagnostik'}
                  ref={ref}
                  type="checkbox"
                  onChange={handleChange}
                  {...props}
                />
              )}
            />
            <Text>Diagnostik</Text>
          </Label>

          <Label>
            <Controller
              control={control}
              name="interest"
              render={({ field: { onChange, value, ref, ...props } }) => (
                <Checkbox
                  checked={interestArray.includes('Therapie')}
                  value={'Therapie'}
                  ref={ref}
                  type="checkbox"
                  onChange={handleChange}
                  {...props}
                />
              )}
            />
            <Text>Therapie</Text>
          </Label>
        </Col>
        <Col>
          <Label>
            <Controller
              control={control}
              name="interest"
              render={({ field: { onChange, value, ref, ...props } }) => (
                <Checkbox
                  checked={interestArray.includes('Patientenmanagement')}
                  value={'Patientenmanagement'}
                  ref={ref}
                  type="checkbox"
                  onChange={handleChange}
                  {...props}
                />
              )}
            />
            <Text>Patientenmanagement</Text>
          </Label>
          <Label>
            <Controller
              control={control}
              name="interest"
              render={({ field: { onChange, value, ref, ...props } }) => (
                <Checkbox
                  checked={interestArray.includes('Fortbildung')}
                  value={'Fortbildung'}
                  ref={ref}
                  type="checkbox"
                  onChange={handleChange}
                  {...props}
                />
              )}
            />
            <Text>Fortbildung</Text>
          </Label>
        </Col>
        <Col>
          <Label>
            <Controller
              control={control}
              name="interest"
              render={({ field: { onChange, value, ref, ...props } }) => (
                <Checkbox
                  checked={interestArray.includes('Patientenmaterial')}
                  value={'Patientenmaterial'}
                  ref={ref}
                  type="checkbox"
                  onChange={handleChange}
                  {...props}
                />
              )}
            />
            <Text>Patientenmaterial</Text>
          </Label>
          <Label>
            <Controller
              control={control}
              name="interest"
              render={({ field: { onChange, value, ref, ...props } }) => (
                <Checkbox
                  checked={interestArray.includes('Sonstiges')}
                  value={'Sonstiges'}
                  ref={ref}
                  type="checkbox"
                  onChange={handleChange}
                  {...props}
                />
              )}
            />
            <Wrap>
              <Controller
                control={control}
                name="interestOther"
                defaultValue=""
                render={({ field: { onChange, value, ref, name } }) => (
                  <CustomInput
                    css={{
                      border: 0,
                      borderBottom: '1px solid $border',
                      borderRadius: 0,
                      padding: '5px 0',
                      fontSize: 17,
                      height: 'auto',
                      div: {
                        paddingLeft: 0,
                      },
                    }}
                    ref={ref}
                    required={interestArray.includes('Sonstiges')}
                    error={!!errors?.interestOther}
                    type="text"
                    value={value}
                    onChange={onChange}
                    placeholder="Sonstiges"
                    errorMessage="Bitte machen Sie eine Angabe"
                  />
                )}
              />
            </Wrap>
          </Label>
        </Col>
      </Content>
    </Box>
  );
};

const Box = styled('div', {
  marginTop: 70,
});

const Message = styled('div', {
  marginBottom: 50,
  textAlign: 'center',
  fontSize: 25,
  color: '$placeholder',
  variants: {
    error: {
      true: {
        color: '$error',
        fontWeight: 600,
      },
    },
  },
});

const Content = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 25,
  maxWidth: 880,
  margin: 'auto',
});

const Label = styled('label', {
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: 20,
});

const Checkbox = styled('input', {
  margin: '5px 30px 0 0',
  transform: 'scale(2)',
});

const Text = styled('div', {
  fontSize: 17,
  lineHeight: '21px',
  letterSpacing: '-0.56px',
  color: '$darkGreen',
  maxWidth: 840,
});

const Wrap = styled('div', {
  width: '100%',
});

const Col = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});
