import { styled } from 'stitches.config';
import { imageFiles } from 'utils';

type Props = {
  counter: number;
  onClose: () => void;
};
export const Step6 = ({ counter, onClose }: Props) => {
  return (
    <Content>
      <Title>Vielen Dank!</Title>
      <Text>Gerne halten wir Sie zur EGPA-Erkrankung auf dem Laufenden.</Text>
      <Icon src={imageFiles.checkIcon} alt="check" />
      <Button onClick={onClose}>zur Startseite</Button>
      <Text>
        Dieses Fenster schließt sich nach {counter} Sekunden automatisch…
      </Text>
    </Content>
  );
};

const Content = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});

const Title = styled('div', {
  fontSize: 45,
  color: '$placeholder',
  marginBottom: 15,
});

const Text = styled('div', {
  fontSize: 25,
  lineHeight: '21px',
  letterSpacing: '-0.85px',
  color: '$darkGreen',
  maxWidth: 840,
});

const Icon = styled('img', {
  height: 205,
  margin: '90px 0 90px',
});

const Button = styled('button', {
  margin: '0 auto 45px',
  letterSpacing: '0.04em',
  fontSize: 22,
  fontWeight: 500,
  backgroundColor: 'transparent',
  color: '#630716',
  borderRadius: 100,
  textAlign: 'center',
  padding: '31px 75px',
  cursor: 'pointer',
  border: '1px solid #630716',
});
