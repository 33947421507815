import Dexie, { Table } from 'dexie';
import { DataSchemaType } from 'store';
import { dbKey } from './constants';

type User = DataSchemaType & {
  id: string;
  date: Date;
};

type Video = DataSchemaType & {
  id: string;
  data: Uint8Array[];
};

export class MySubClassedDexie extends Dexie {
  // 'users' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  users!: Table<User>;
  videos!: Table<Video>;
  constructor() {
    super(dbKey);
    this.version(2).stores({
      users:
        '++id, email, salutation, title, firstName, lastName, specialization, interest, institution, street, house_number, zip_code, city, isConsentChecked, date', // Primary key and indexed props
      // Do not index video data: https://github.com/dexie/Dexie.js/issues/667#issuecomment-367329077
      videos: '++id',
    });
  }
}

export const db = new MySubClassedDexie();
