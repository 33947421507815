export type Dot = {
  id: number;
  headline: string;
  description: string;
  left: number;
  top: number;
};

export const dots: Dot[] = [
  {
    id: 1,
    headline: 'Nase',
    description: 'Allergische Rhinitis, Sinusitis, nasale Polypen',
    left: 171,
    top: 64,
  },
  {
    id: 2,
    headline: 'Nervensystem',
    description: 'Neuropathie',
    left: 173,
    top: 160,
  },
  {
    id: 3,
    headline: 'Lungen',
    description: 'Asthma, alveoläre Blutung, Lungeninfiltration',
    left: 120,
    top: 240,
  },
  {
    id: 4,
    headline: 'Herz',
    description: 'Herzinsuffizienz, Perikarditis, Kardiomyopathie, Arrhythmie',
    left: 220,
    top: 300,
  },
  {
    id: 5,
    headline: 'Muskeln/Gelenke',
    description: 'Z.B. Myalgie, Arthralgie',
    left: 15,
    top: 410,
  },
  {
    id: 6,
    headline: 'Nieren',
    description: 'Akute Niereninsuffizienz',
    left: 110,
    top: 470,
  },
  {
    id: 7,
    headline: 'Gastrointestinal',
    description: 'Z.B. Blutung',
    left: 260,
    top: 570,
  },
  {
    id: 8,
    headline: 'Haut',
    description: 'Z.B. Purpura',
    left: 93,
    top: 690,
  },
];
