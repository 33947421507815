import { create } from 'zustand';
import { z } from 'zod';

export const DataSchema = z.object({
  email: z.string().optional(), // we can't use email type in our case, because in that case we have an "email" error if a user clicks nextStep
  salutation: z.string().optional(),
  title: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  specialization: z.string().optional(),
  isConsentChecked: z.boolean().optional(),
  interest: z.array(z.string().optional()).optional(),
  interestOther: z.string().optional(),
  institution: z.string().optional(),
  street: z.string().optional(),
  house_number: z.string().optional(),
  zip_code: z.string().optional(),
  city: z.string().optional(),
});

export type DataSchemaType = z.infer<typeof DataSchema>;

export type DataStore = {
  isFormOpen: boolean;
  step: number;
  formData: DataSchemaType;
};

export const dataStoreDefaults: DataStore = {
  isFormOpen: false,
  step: 1,
  formData: {
    email: '',
    salutation: '',
    title: '',
    firstName: '',
    lastName: '',
    specialization: '',
    isConsentChecked: false,
    interest: [],
    institution: '',
    street: '',
    house_number: '',
    zip_code: '',
    city: '',
  },
};

export const useDataStore = create<DataStore>(() => ({
  ...dataStoreDefaults,
}));

export const useDataStoreValues = () =>
  useDataStore(({ isFormOpen, step, formData }) => ({
    isFormOpen,
    step,
    formData,
  }));
