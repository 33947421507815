import { db } from 'db';
import { styled } from 'stitches.config';
import writeXlsxFile from 'write-excel-file';
import dayjs from 'dayjs';
import { useState } from 'react';

const HEADER_ROW = [
  {
    value: 'Id',
    fontWeight: 'bold',
  },
  {
    value: 'Email',
    fontWeight: 'bold',
  },
  {
    value: 'Salutation',
    fontWeight: 'bold',
  },
  {
    value: 'Title',
    fontWeight: 'bold',
  },
  {
    value: 'Name',
    fontWeight: 'bold',
  },
  {
    value: 'Surname',
    fontWeight: 'bold',
  },
  {
    value: 'Specialization',
    fontWeight: 'bold',
  },
  {
    value: 'Interest',
    fontWeight: 'bold',
  },
  {
    value: 'Interest other',
    fontWeight: 'bold',
  },
  {
    value: 'Institution',
    fontWeight: 'bold',
  },
  {
    value: 'Street',
    fontWeight: 'bold',
  },
  {
    value: 'House number',
    fontWeight: 'bold',
  },
  {
    value: 'Zip code',
    fontWeight: 'bold',
  },
  {
    value: 'City',
    fontWeight: 'bold',
  },
  {
    value: 'Consent',
    fontWeight: 'bold',
  },
  {
    value: 'Date',
    fontWeight: 'bold',
  },
];

export const ExportPage = () => {
  const [dbCleared, setDBCleared] = useState(false);
  const pad2 = (n: number) => (n < 10 ? '0' : '') + n;

  const currentDate = () => {
    const d = new Date();
    return (
      d.getFullYear() +
      pad2(d.getMonth() + 1) +
      pad2(d.getDate()) +
      '-' +
      pad2(d.getHours()) +
      pad2(d.getMinutes()) +
      pad2(d.getSeconds())
    );
  };

  const exportAll = async () => {
    const entries = await db.users.toArray();
    const data: any = [HEADER_ROW];

    entries.forEach((user) => {
      data.push([
        { value: user.id },
        { value: user.email },
        { value: user.salutation },
        { value: user.title },
        { value: user.firstName },
        { value: user.lastName },
        { value: user.specialization },
        { value: user.interest },
        { value: user.interestOther },
        { value: user.institution },
        { value: user.street },
        { value: user.house_number },
        { value: user.zip_code },
        { value: user.city },
        { value: user.isConsentChecked },
        { value: dayjs(user.date).format('MMMM D, YYYY h:mm A') },
      ]);
    });

    await writeXlsxFile(data, {
      // columns, // (optional) column widths, etc.
      fileName: `gsk_egpa_submissions_${currentDate()}.xlsx`,
    });
  };

  const clearDatabase = async () => {
    await db.users.clear();
    setDBCleared(true);
  };

  const goBack = async () => {
    window.location.replace('/');
  };

  return (
    <Container>
      <ExportButton onClick={exportAll}>Export data</ExportButton>
      <GoBackButton onClick={goBack}>Go to start</GoBackButton>
      {dbCleared ? (
        <p>Database cleared</p>
      ) : (
        <DeleteButton onClick={clearDatabase}>
          DELETE DATABASE permanently!
        </DeleteButton>
      )}
    </Container>
  );
};

const Container = styled('div', {
  display: 'flex',
  width: '100vw',
  height: '100vh',
  backgroundColor: '$light',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const ExportButton = styled('button', {
  marginBottom: '80px',
  padding: '20px 40px',
  backgroundColor: '#630716',
  fontSize: 45,
  border: 'none',
  color: '$light',
  borderRadius: 50,
  fontWeight: 600,
  cursor: 'pointer',
});

const DeleteButton = styled('button', {
  padding: '20px 40px',
  backgroundColor: '#F00',
  fontSize: 25,
  border: 'none',
  color: '$light',
  borderRadius: 50,
  fontWeight: 600,
  cursor: 'pointer',
});

const GoBackButton = styled('button', {
  marginBottom: '30vh',
  background: 'none',
  border: 0,
  fontSize: 45,
  color: '$darkGreen',
  fontWeight: 600,
});
