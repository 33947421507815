import { styled } from 'stitches.config';
import { Person } from '../Person/Person';

export const MainContent = () => {
  return (
    <Container>
      <InteractionHolder>
        <Person />
      </InteractionHolder>
      <BottomTextHolder>
        <HeadlineBottom>Konstitutionelle Symptome</HeadlineBottom>
        <ParagraphBottom>
          Fatigue (Erschöpfung), Muskelschmerzen. Taubheit/Kribbeln in Armen und
          Beinen
        </ParagraphBottom>
      </BottomTextHolder>
      <MainTextHolder>
        <HeadlineNote>Tippen Sie auf</HeadlineNote>
        <HeadlineMain>
          Hauptsächlich betroffene Organsysteme<sup>2, 3</sup>
        </HeadlineMain>
        <ParagraphMain>
          EGPA kann schwere und lebensbedrohliche Symptome verursachen
          <sup>1</sup>.
        </ParagraphMain>
        <ParagraphMain>
          Haupterscheinungsbild sind Asthma und Blut- oder Gewebeeosinophilie
          <sup>2</sup>.
        </ParagraphMain>
        <ParagraphMain>
          Patienten mit EGPA haben eine verringerte Lebensqualität. Das betrifft
          unter anderem soziale Einschränkungen, die geistige Gesundheit,
          körperliche Schmerzen und die Vitalität
          <sup>4</sup>.
        </ParagraphMain>
      </MainTextHolder>
      <Details>
        1. Gioffredi A et al. Front Immunol 2014;5:549
        <br />
        2. Vaglio A et al. Allergy 2013;68:261-73;
        <br />
        3. Greco A et al. Autoimmun Rev 2015;14:341-48;
        <br />
        4. Sokolowska et al. Clin Rheumatol 2013, Seo P. J Allergy Clin Immunol
        Pract. 2016. 4(3): 520-521.
      </Details>
    </Container>
  );
};

const Container = styled('div', {
  position: 'relative',
  width: '100vw',
  height: '100vh',
  backgroundColor: '$lightMedium',
});

const InteractionHolder = styled('div', {
  position: 'absolute',
  left: 720,
  top: 63,
});

const Details = styled('p', {
  position: 'absolute',
  maxWidth: 375,
  color: '$textMedium',
  fontSize: 10,
  left: 19,
  bottom: 92,
});

const BottomTextHolder = styled('div', {
  position: 'absolute',
  width: 285,
  color: '$textDark',
  fontSize: 20,
  left: 484,
  bottom: 89,
});

const HeadlineBottom = styled('p', {
  color: '$red',
  fontWeight: 500,
  fontSize: 31,
  lineHeight: 1.3,
});

const ParagraphBottom = styled('p', {
  fontSize: 15,
  lineHeight: 1.2,
  marginTop: 8,
  maxWidth: 285,
});

const MainTextHolder = styled('div', {
  position: 'absolute',
  maxWidth: 375,
  color: '$textLight',
  fontSize: 20,
  left: 510,
  top: 80,
});

const HeadlineMain = styled('h1', {
  color: '$textDark',
  fontSize: 30,
  lineHeight: 1.3,
  fontWeight: 500,
  maxWidth: 239,

  '& > sup': {
    fontSize: 20,
  },
});

const HeadlineNote = styled('p', {
  color: '$textDark',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 15,
});

const ParagraphMain = styled('p', {
  color: '$textMedium',
  fontSize: 15,
  lineHeight: 1.2,
  marginTop: 28,
  maxWidth: 237,
});
