export const imageFiles = {
  closeIcon: require('./assets/btn_close.svg').default,
  continueIcon: require('./assets/btn_continue.svg').default,
  backIcon: require('./assets/btn_back.svg').default,
  logo: require('./assets/gsk_logo.png'),
  checkIcon: require('./assets/icon_check.svg').default,
  logo2: require('./assets/cornerbug_nucala.svg').default,
  schemaPerson: require('./assets/schema_person.svg').default,
  redTriangle: require('./assets/icon_red_triangle.svg').default,
  dotActive: require('./assets/btn_dot_active.png'),
};
