import { styled } from 'stitches.config';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { CustomInput } from '..';
import { DataSchemaType } from 'store';

type Props = {
  errors: FieldErrors<DataSchemaType>;
  control: Control<DataSchemaType>;
};

export const Step1 = ({ errors, control }: Props) => {
  const hasError = Object.keys(errors).length > 0;

  return (
    <Content>
      <Message error={hasError}>
        {hasError
          ? 'Bitte geben Sie Ihre E-Mail-Adresse an'
          : 'Registrieren Sie sich hier mit Ihrer E-Mail-Adresse für Updates von Ihren EGPA-Spezialisten.'}
      </Message>
      <Item>
        <Wrap>
          <Controller
            control={control}
            name="email"
            defaultValue=""
            render={({ field: { onChange, value, ref, name } }) => (
              <CustomInput
                autoFocus
                ref={ref}
                error={!!errors?.email}
                type="email"
                value={value}
                onChange={onChange}
                placeholder="E-Mail-Adresse*"
                errorMessage="Bitte geben Sie Ihre E-Mail-Adresse an"
              />
            )}
          />
        </Wrap>
      </Item>
    </Content>
  );
};

const Content = styled('div', {
  marginTop: 65,
});

const Message = styled('div', {
  marginBottom: 45,
  textAlign: 'center',
  fontSize: 25,
  color: '$placeholder',
  variants: {
    error: {
      true: {
        color: '$error',
        fontWeight: 600,
      },
    },
  },
});

const Wrap = styled('div', {
  marginBottom: 10,
  display: 'flex',
  alignItems: 'flex-start',
  gap: 60,
});

const Item = styled('div', {
  flex: 1,
});
